import React from "react"
import { Link } from "gatsby"
import { ChainId, DAppProvider, useEtherBalance, useEthers } from '@usedapp/core'
import { usePromiseTracker, trackPromise } from "react-promise-tracker"
import Loader from 'react-loader-spinner';
import Layout from "../components/layout"
import Api from "../utils/api.js"
import MintDapp from "../components/mint_dapp"

const api = new Api()

const dAppConfig = {
}

const bannerMsgs = {
  "mintSuccess": {
    "header": "Mint Successful!",
    "content": "We hope your Monstera brings you joy!"
  },
  "mintFail": {
    "header": "Mint Failed!",
    "content": "It may have been cancelled or the mint has sold out."
  },
  "generalError": {
    "header": "Oh No!",
    "content": "An error occurred!"
  }
}

export default class Mint extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.reload = this.reload.bind(this);
  }

  componentDidMount() {
  }

  reload() {
  }

  render() { return (
    <Layout>

      {/* <div class="mint-soon-container">
        <div class="mint-soon">
          <div class="mint-soon-text">Minting Available on Oct 31!</div>
        </div>
      </div> */}

      <DAppProvider config={dAppConfig}>
        <MintDapp />
      </DAppProvider>

    </Layout>
  )}
}


