import React from "react"
import { useState, useEffect } from 'react';
import { Link } from "gatsby"
import Loader from 'react-loader-spinner';
import { ChainId, DAppProvider, useEtherBalance, useEthers, useContractFunction, useContractCall } from '@usedapp/core'
import { formatEther } from '@ethersproject/units'
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { getContractData, getContractAddress, useSimpleContractCall, useArglessContractCall } from "../utils/contract.js"

export default function MintDapp(props) {

  const MintLoadingIndicator = props => {
    return (
      <div style={{
          marginTop: "20px",
          width: "100%",
          height: "40",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <Loader type="BallTriangle" color="#44615a" height="40" width="40" />
      </div>
    );  
  }

  const { activateBrowserWallet, account } = useEthers()

  const mmInterface = new utils.Interface(getContractData().abi)
  const mmContractAddress = getContractAddress()
  const mmContract = new Contract(mmContractAddress, mmInterface)
  
  const mmsMinted = useArglessContractCall(mmContract, 'totalMint')
  const mmsTotal = useArglessContractCall(mmContract, 'MAX_ELEMENTS')
  const mmsSaleClosed = useArglessContractCall(mmContract, 'paused')
  const mmsPartnerNfts = useSimpleContractCall(mmContract, 'vipIdsClaimable', account)

  const { state:mintState, send:mintSend } = useContractFunction(mmContract, 'mint', { transactionName: 'Mint' })
  const { state:mintStateFree, send:mintSendFree } = useContractFunction(mmContract, 'vipMint', { transactionName: 'Free Mint' })

  const [lastTerminalMintState, setLastTerminalMintState] = useState(null);

  const [connectDelaying, setConnectDelaying] = useState(true);
  const [bannerOpen, setBannerOpen] = useState(0);
  const [bannerContent, setBannerContent] = useState(0);
  const [userMintCount, setUserMintCount] = useState(1);
  const [totalMintCount, setTotalMintCount] = useState('?');
  const [totalMaxMintCount, setTotalMaxMintCount] = useState('?');
  const [saleClosed, setSaleClosed] = useState(true);
  const [partnerMintOpen, setPartnerMintOpen] = useState(false);
  const [partnerNfts, setPartnerNfts] = useState([]);

  setTimeout(() => {
    setConnectDelaying(false)
  }, 300);

  const onWalletActivationError = (error) => {
    console.log(error.message)
    setBannerContent(error.message)
    setBannerOpen(true)
  }

  const attemptToMint = () => {
    if (mintState && mintState.status == "Mining") {
      setBannerContent("Mint Already In Progress!")
      setBannerOpen(true)
      return
    }
    else if (userMintCount > 5 || userMintCount < 1) {
      setBannerContent("Only 1-5 Monsteras per Transaction")
      setBannerOpen(true)
      return
    }
    setBannerOpen(false)
    console.log("Minting!!!")
    mintSend(account, userMintCount, { value: utils.parseEther("0") })
  }

  const attemptToFreeMint = (partnerNftIds) => {
    if (mintStateFree && mintStateFree.status == "Mining") {
      setBannerContent("Mint Already In Progress!")
      setBannerOpen(true)
      return
    }
    setBannerOpen(false)
    console.log("Minting!!!")

    let partnerNftStringIds = partnerNftIds.map((bigInt) => {
      return utils.formatUnits(bigInt, 0)
    })

    console.log(partnerNftStringIds)

    mintSendFree(account, partnerNftStringIds, { value: utils.parseEther("0") })
  }

  const onMintSuccess = () => {
    if (lastTerminalMintState != 'Success') {
      setBannerContent("Mint Successful!")
      setBannerOpen(true)
      setLastTerminalMintState('Success')
    }
  }

  const onMintFail = (error) => {
    if (lastTerminalMintState != error) {
      setBannerContent("Mint Failed: "+error)
      setBannerOpen(true)
      setLastTerminalMintState(error)
    }
  }

  const showPartnerMint = () => {
    setPartnerMintOpen(true)
  }

  const hidePartnerMint = () => {
    setPartnerMintOpen(false)
  }

  
  

  useEffect(() => {
    if (mintState && mintState.status == 'Success') {
      console.log(mintState.transaction)
      onMintSuccess()
    }
    else if (mintState && (mintState.status == 'Fail' || mintState.status == 'Exception')) {
      console.log(mintState.transaction)
      onMintFail(mintState.errorMessage || "Unknown Error")
    }

    if (mintStateFree && mintStateFree.status == 'Success') {
      console.log(mintStateFree.transaction)
      onMintSuccess()
    }
    else if (mintStateFree && (mintStateFree.status == 'Fail' || mintStateFree.status == 'Exception')) {
      console.log(mintStateFree.transaction)
      onMintFail(mintStateFree.errorMessage || "Unknown Error")
    }

    if (mmsMinted) {
      console.log(mmsMinted)
      setTotalMintCount(parseInt(utils.formatUnits(mmsMinted, 0)))
    }

    if (mmsTotal) {
      console.log(mmsTotal)
      setTotalMaxMintCount(parseInt(utils.formatUnits(mmsTotal, 0)))
    }

    if (mmsSaleClosed != null) {
      console.log(mmsSaleClosed)
      setSaleClosed(mmsSaleClosed)
    }

    if (mmsPartnerNfts != null) {
      console.log(mmsPartnerNfts)
      setPartnerNfts(mmsPartnerNfts)
    }
    
  }, [mintState, mintStateFree, mmsMinted, mmsTotal, mmsSaleClosed, mmsPartnerNfts])
  


  return (
    
    <div class="dapp">

      {!account && 
      <div class={bannerOpen ? "open banner-container" : "closed banner-container"}>
        <div class="banner-padding">
          <div class="banner-close-button" role="button" tabIndex={0} aria-label="close" onKeyDown={() => setBannerOpen(false)} onClick={() => setBannerOpen(false)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg></div>
          <div class="banner-content">{bannerContent}</div>
        </div>
      </div>}
        
      {!account && 
      <div class={(!account && !connectDelaying) ? "connect-container open" : "connect-container"}>
        <p class="connect-text">While using a browser-based wallet (ie: MetaMask), click "Connect Wallet" below to prepare to mint a Moody Monstera!</p>
        <div class={account && "button disabled" || "button"} role="button" disabled={account} onClick={() => activateBrowserWallet(onWalletActivationError)} onKeyDown={() => activateBrowserWallet(onWalletActivationError)}>
          <div class="button-text">{(account && "Connected!") || "Connect Wallet"}</div>
        </div>
      </div>}

      <div class={account ? "did-connect-container open" : "did-connect-container"}>
        <p class="wallet-text">Your Address is:<br/><span class="wallet-text-sm">{account}</span></p>
        <p class="wallet-text">Moody Monsteras are FREE to mint! (+ gas)<br/>1-5 NFTs Per Transaction</p>
        <p class="wallet-text">Once minted, view your instantly revealed Moody Monsteras in the <Link to="/gallery">Gallery!</Link></p>
        <p class="wallet-text"><b>Happy Minting!</b></p>

        <hr/>

        <div class={bannerOpen ? "open banner-container" : "closed banner-container"}>
          <div class="banner-padding">
            <div class="banner-close-button" role="button" tabIndex={0} aria-label="close" onKeyDown={() => setBannerOpen(false)} onClick={() => setBannerOpen(false)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg></div>
            <div class="banner-content">{bannerContent}</div>
          </div>
        </div>

        <div class="nfts-available">{totalMintCount} / {totalMaxMintCount}</div>
        <div class="nfts-available-text">Total Monsteras Minted</div>

        {!saleClosed && (totalMintCount < totalMaxMintCount) && !partnerMintOpen &&
        <div class="sale-open">
          <div class="mint-count">
            <label for="user-mint-count"> How many Moody Monsteras do you want (1-5)?
              <span class="mint-count-container"><input id="user-mint-count" type="number" value={userMintCount} onChange={(event) => setUserMintCount(event.target.value)}/></span>
            </label>
          </div>
          <div class={mintState && mintState.status == "Mining" && "button disabled" || "button"} role="button" disabled={mintState && mintState.status == "Mining"} onClick={() => attemptToMint()} onKeyDown={() => attemptToMint()}>
            <div class="button-text">{(mintState && mintState.status == "Mining" && "Minting...") || "Mint"}</div>
          </div>
          {mintState && mintState.status == "Mining" && <MintLoadingIndicator/>}
        </div>}

        {!saleClosed && (totalMintCount < totalMaxMintCount) && partnerMintOpen && partnerNfts &&
        <div class="sale-open">

          {partnerNfts.length <= 0 && 
            <div>Sorry.<br/>Your wallet does not contain any qualifying NFTs.</div>
          }

          {partnerNfts.length > 0 && 
          <div>
            <div class="mint-free-text">You have {partnerNfts.length} Qualifying NFTs!<br/>Tap the button below to mint your free Monsteras.</div>
            <div class={mintStateFree && mintStateFree.status == "Mining" && "button disabled" || "button"} role="button" disabled={mintStateFree && mintStateFree.status == "Mining"} onClick={() => attemptToFreeMint(partnerNfts)} onKeyDown={() => attemptToFreeMint(partnerNfts)}>
              <div class="button-text">{(mintStateFree && mintStateFree.status == "Mining" && "Minting...") || "Free Mint"}</div>
            </div>
            {mintStateFree && mintStateFree.status == "Mining" && <MintLoadingIndicator/>}
          </div>}

        </div>}

        {saleClosed && (totalMintCount < totalMaxMintCount) &&
        <div class="sale-closed">
          <div class="sale-closed-title">The Sale is Currently Closed...</div>
          <div class="sale-closed-text">When the sale opens, this page will automatically refresh</div>
        </div>}

        {(totalMintCount >= totalMaxMintCount) && 
        <div class="sale-closed">
          <div class="sale-closed-title">Sold Out!</div>
          <div class="sale-closed-text">But no worries! You can still buy on <Link to="https://opensea.io/collection/moodymonsteras">OpenSea</Link> or <Link to="https://looksrare.org/collections/0x5EE1a37E30d5C5d909B519b7cDf58F8463dE8262">LooksRare</Link>!</div>
        </div>}

        {/* {!saleClosed && (totalMintCount < totalMaxMintCount) && !partnerMintOpen &&
          <div class="partner-button-container">
            <span class="partner-button" role="button" onClick={() => showPartnerMint()} onKeyDown={() => showPartnerMint()}>Own a Partnering NFT?</span>
          </div>
        } */}

        {!saleClosed && (totalMintCount < totalMaxMintCount) && partnerMintOpen &&
          <div class="partner-button-container">
            <span class="partner-button" role="button" onClick={() => hidePartnerMint()} onKeyDown={() => hidePartnerMint()}>&lt;&nbsp;Back</span>
          </div>
        }

      </div>

    </div>
  )
}
